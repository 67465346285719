// basic styles
//@import "style_base.scss";

// breakpoint max-width: 48rem
@media screen and (max-width: 48rem) {
  @import "general SCSS files/menu_max_47-9375rem";
  @import "general SCSS files/footer_max_47-9375rem";
  @import "ContentElements/blog_detail/blog_detail_max_47-9375rem.scss";
  @import "ContentElements/blog_overview/blog_overview_max_47-9375rem.scss";
  @import "ContentElements/calendar/calendar_max_47-9375rem.scss";
  @import "ContentElements/gallery/gallery_max_47-9375rem.scss";
  @import "ContentElements/header_and_button/header_and_button_max_47-9375rem.scss";
  @import "ContentElements/image_textbox/image_textbox_max_47-9375rem.scss";
  @import "ContentElements/logo_gallery/logo_gallery_max_47-9375rem.scss";
  @import "ContentElements/service/service_max_47-9375rem.scss";
  @import "ContentElements/team/team_max_47-9375rem.scss";
  @import "callrotation/callrotation_max_47-9375rem.scss";
}
