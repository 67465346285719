.frame.frame-default.frame-type-list.frame-layout-0 {

  .callrotation_container {

    .emergency_current, .emergency_next {
      margin: 0 -1rem 3rem -1rem;
    }

    .desktop_version {
      display: none;
    }

    .mobile_version {
      display: block;
      //border: 0.125rem solid white;
      width: 100%;
      padding: 1rem;

      .callrotation_employee {
        display: grid;
        grid-template-columns: auto 1fr;
        background-color: rgba(0,0,0,0.3);
        border: 0.125rem solid white;
        padding: 1rem;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        p {
          font-family: Montserrat-Regular, sans-serif;
          margin-bottom: 0;
          white-space: nowrap;
          filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
          color: white;

          &:nth-of-type(odd) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}