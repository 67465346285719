// team max 47.9375rem

.team {

  .team_container {

    .team_member {
      width: 50%;
      max-width: 50%;
      min-width: 50%;
      height: 50vw;
    }
  }
}