//footer max 47.9375rem

.footer {

  .footer_container {

    div {
      width: 50%;
      padding: 3rem 1rem;
    }
  }

  .legal_container {

    li {
        padding: 0.5rem 3rem 0.5rem 1rem;
    }
  }
}