//calendar max 47.9375rem

.calendar {

  .calendar_container {

    .calendar_person {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      .calendar_person_image_container {
        padding-right: 0;
        max-width: 16rem;
        max-height: 16rem;
        margin: 0 auto 3rem;
      }

      .calendar_person_text {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}