// blog_detail max 47.9375rem

.blog_detail {

  .blog_header {

    h1 {
      padding: 1rem;
      font-size: 2.5rem;

      span {
        font-size: 1.25rem;
      }
    }
  }

  .blog_detail_container {

    .blog_detail_part {

      .quote {

        p {
          font-size: 1.5rem;
        }
      }


      .blog_detail_quoteonly {

        p {
          font-size: 2rem;
        }
      }

      .blog_detail_imageandtext {

        .image {
          width: 100%;
          height: 56vw;
          margin-bottom: 4rem;
        }

        .text {
          width: 100%;
        }
      }

      .blog_detail_textandquote {

        .quote {
          padding-left: 2rem;
        }

        &.quoteleft {

          .quote {
            padding-left: 0;
            padding-right: 2rem;
          }
        }
      }

      .blog_detail_imageandquote {

        .quote {
          padding-left: 2rem;
        }

        &.quoteleft {

          .quote {
            padding-left: 0;
            padding-right: 2rem;
          }
        }
      }
    }
  }
}