// image_textbox max 47.9375rem

.image_textbox {

  .textbox {

    .textbox_header {
      padding: 2rem 1.5rem;

      h2 {
        font-size: 2.125rem;
        max-width: 100%;

        em {
          font-size: 2.5rem;
        }
      }
    }

    .textbox_text {
      padding: 2rem 1.5rem;
    }
  }
}