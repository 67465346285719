// menu max 47.9375

nav {

  .main.navbar {

    .navbar-brand {
      img {
        width: 7.5rem;
        height: auto;
      }

      span {
        font-size: 1rem;
      }
    }
  }
}


