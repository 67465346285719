// service max 47.9375rem

.service {

  .service_container {
    display: none;
  }

  .service_carousel {
    display: block;
  }
}