// blog_overview max 47.9375rem

.blog_overview {

  .blog_filter {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .blog_overview_blocks {

    .blog_overview_block {
      max-width: calc(100% - 0.8rem);
      width: calc(100% - 0.8rem);
      min-width: calc(100% - 0.8rem);
    }
  }
}