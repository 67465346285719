// header_and_button max 47.9375rem

.header_and_button {

  .header_and_button_container {
    -ms-flex-direction: column;
    flex-direction: column;

    h2 {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .hollow_button {
      margin-left: 0;
    }
  }
}